@import "ember-modal-dialog/ember-modal-structure";
@import "ember-modal-dialog/ember-modal-appearance";
@import "estilosAjuste.scss"; //CSS para boa apresentação de certos componentes
@import "estilosModal.scss"; //CSS para ajuste das modais
@import "breadcrumbs.scss"; //CSS do componente bread-crumbs
@import "estilosMenu.scss"; // CSS do menu
@import "estilosCores.scss"; // CSS de cores
@import "estilosTabelas.scss"; // CSS das tabelas
@import "estilosBotaoGeral.scss"; // CSS para o componente Botao Geral
@import "estilosTransparencia.scss"; // CSS para o componente Botao Geral

//colors
$color_dove_gray_approx: #737070;
$color_alto_approx: #dadada;
$color_celeste_approx: #cccccc;
$red: red;
$color_desert_storm_approx: #f8f8f8;
$color_oslo_gray_70_approx: rgba(143, 142, 145, .7 );
$silver: silver;
$white_30: rgba(255, 255, 255, 0.3);
$color_alabaster_approx: #fcfcfc;
$color_pink_swan_approx: #b9b9b9;
$color_cararra_approx: #ebebeb;
$white: #ffffff;
$color_chicago_approx: #595757;
$wild_sand: #f4f4f4;

//fonts
$font_0: Roboto;
$font_1: sans-serif;

//urls
$url_0: url(img/fundotrt.jpg);
$url_1: url(img/cabecalho.png);

//@extend-elements
//original selectors
//.navbar-custom .navbar-nav > .active > a, .navbar-custom .navbar-nav > .active > a:hover, .navbar-custom .navbar-nav > .active > a:focus
%extend_1 {
	color: $color_dove_gray_approx;
	background-color: $color_alto_approx;
	background-image: linear-gradient(to bottom, $color_alto_approx 0, $wild_sand 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdadada', endColorstr='#fff4f4f4', GradientType=0);
}


body {
	font-family: $font_0, $font_1;
	font-weight: 400;
	background: $url_0 repeat;
}
.active {
	font-weight: bold;
}
.edit {
	//Instead of the line below you could use @includeword-break($value)
	word-break: break-word;
	border: 0;
	background: $color_desert_storm_approx;
	margin-left: 15px;
	display: inline;
	line-height: 1.2;
	//Instead of the line below you could use @includetransition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: color 0.4s;
}
.fundoVermelho {
    background-color: red !important
}
.acao {
	margin: 0;
	padding: 0;
	border: 0;
	background: none;
	font-size: 100%;
	vertical-align: baseline;
	font-family: inherit;
	color: inherit;
	appearance: none;
	top: 0;
	right: 10px;
	bottom: 0;
	height: inherit;
	margin: auto 0;
	font-size: 18px;
	//Instead of the line below you could use @includetransition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
	transition: all 0.2s;
}
.wait {
	display: block;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: $color_oslo_gray_70_approx 50% 50% no-repeat;
}
.table {
	td {
		text-align: center;
	}
	th {
		text-align: center;
	}
}
.containerTabela {
	max-height: 300px;
	overflow-y: scroll;
}
button {
	i {
		margin-right: 3px;
	}
	&:hover .acao {
		display: block;
	}
}
.create {
	float: right;
	&:after {
		content: '+';
	}
}
.destroy {
	float: right;
}
.atribui {
	display: block;
}
.retira {
	display: block;
}
.contemAtribuiveis {
	border-style: solid;
	border-width: 1px;
	border-color: $silver;
	//Instead of the line below you could use @includeborder-radius($radius, $vertical-radius)
	border-radius: 5px;
	height: 100%;
}
.col-centered {
	float: none;
	margin: 0 auto;
	div.form-group {
		background-color: $color_alabaster_approx;
		border: $color_pink_swan_approx 1px solid;
		padding: 30px;
	}
}
div {
	&.headerLogotipo {
		height: 100px;
		background: $url_1;
		background-size: 100%;
	}
	&.navbar {
		//Instead of the line below you could use @includetext-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
		text-shadow: 0 1px 0 $white_30;
	}
}
fieldset {
}
.centerButtons {
	width: 50%;
	margin: 10px auto;
	text-align: center;
}
legend {
	font-size: 16px;
}
#botaoSubmissao {
}
.navbar-custom {
	background-color: $color_cararra_approx;
	border-color: $color_alto_approx;
	background-image: linear-gradient(to bottom, $white 0, $color_cararra_approx 100%);
	background-repeat: repeat-x;
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffebebeb', GradientType=0);
	.navbar-brand {
		color: $color_dove_gray_approx;
		&:hover {
			color: $color_chicago_approx;
			background-color: transparent;
		}
		&:focus {
			color: $color_chicago_approx;
			background-color: transparent;
		}
	}
	.navbar-text {
		color: $color_dove_gray_approx;
	}
	.navbar-toggle {
		border-color: $color_alto_approx;
		&:hover {
			background-color: $color_alto_approx;
		}
		&:focus {
			background-color: $color_alto_approx;
		}
		.icon-bar {
			background-color: $color_celeste_approx;
		}
	}
	.navbar-collapse {
		border-color: $color_alto_approx;
	}
	.navbar-form {
		border-color: $color_alto_approx;
	}
	.navbar-link {
		color: $color_dove_gray_approx;
		&:hover {
			color: $color_dove_gray_approx;
		}
	}
	.navbar-nav > {
		li > a {
			color: $color_dove_gray_approx;
			&:hover {
				color: $color_dove_gray_approx;
				background-color: transparent;
			}
			&:focus {
				color: $color_dove_gray_approx;
				background-color: transparent;
			}
		}
		.active > a {
			@extend %extend_1;
			&:hover {
				@extend %extend_1;
			}
			&:focus {
				@extend %extend_1;
			}
		}
		.disabled > a {
			color: $color_celeste_approx;
			background-color: transparent;
			&:hover {
				color: $color_celeste_approx;
				background-color: transparent;
			}
			&:focus {
				color: $color_celeste_approx;
				background-color: transparent;
			}
		}
		.dropdown > a {
			.caret {
				border-top-color: $color_dove_gray_approx;
				border-bottom-color: $color_dove_gray_approx;
			}
			&:hover .caret {
				border-top-color: $color_dove_gray_approx;
				border-bottom-color: $color_dove_gray_approx;
			}
			&:focus .caret {
				border-top-color: $color_dove_gray_approx;
				border-bottom-color: $color_dove_gray_approx;
			}
		}
		.open > a {
			background-color: $color_alto_approx;
			color: $color_dove_gray_approx;
			&:hover {
				background-color: $color_alto_approx;
				color: $color_dove_gray_approx;
				.caret {
					border-top-color: $color_dove_gray_approx;
					border-bottom-color: $color_dove_gray_approx;
				}
			}
			&:focus {
				background-color: $color_alto_approx;
				color: $color_dove_gray_approx;
				.caret {
					border-top-color: $color_dove_gray_approx;
					border-bottom-color: $color_dove_gray_approx;
				}
			}
			.caret {
				border-top-color: $color_dove_gray_approx;
				border-bottom-color: $color_dove_gray_approx;
			}
		}
	}
}
@media(max-width: 767) {
	.navbar-custom .navbar-nav .open .dropdown-menu > {
		li > a {
			color: $color_dove_gray_approx;
			&:hover {
				color: $color_dove_gray_approx;
				background-color: transparent;
			}
			&:focus {
				color: $color_dove_gray_approx;
				background-color: transparent;
			}
		}
		.active > a {
			color: $color_dove_gray_approx;
			background-color: $color_alto_approx;
			&:hover {
				color: $color_dove_gray_approx;
				background-color: $color_alto_approx;
			}
			&:focus {
				color: $color_dove_gray_approx;
				background-color: $color_alto_approx;
			}
		}
		.disabled > a {
			color: $color_celeste_approx;
			background-color: transparent;
			&:hover {
				color: $color_celeste_approx;
				background-color: transparent;
			}
			&:focus {
				color: $color_celeste_approx;
				background-color: transparent;
			}
		}
	}
}

.ember-select selected {
	background-color: $red;
}
li:hover {
	.atribui:after {
		content: '⇒';
	}
	.retira:before {
		content: '⇦ ';
	}
}

/*  */
.paddingZero {
    padding: 0;
}

.paddingLeftZero {
    padding-left: 0;
}

/* MENU */

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px;
    border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover>.dropdown-menu {
    display: block;
}

.dropdown-submenu>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
}

.dropdown-submenu:hover>a:after {
    border-left-color: #fff;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}


/* geral */

.usar-toda-area {
  width: 100%;
  height: 100%;
}

.abaixo {
  z-index: -1;
}

.acima {
  z-index: 200000;
}

.caixa-3d		{
  box-shadow: 5px 5px 10px grey;
}

.rebaixado		{
	margin-top: 2px;
}

/* sobrescrevendo o bootstrap */

.panel {   margin-bottom: 8px; }
.panel-body { padding: 8px; }

