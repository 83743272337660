body {
  background: #d9d9d9 !important;
}

body,
body > div.ember-view,
html,
.sidebar-div {
  height: 100%;
}

/* Botão de abrir e fechar o menu */
//#btnAbreFechaMenu {
.btnAbreFechaMenu {
  border:none;
  border-right:solid 1px #e5e5e5;
  background: transparent;
  width:41px;
  height:50px;
  float:left;
}

//#btnAbreFechaMenu:hover {
.btnAbreFechaMenu:hover {
  background-color:#e5e5e5;
}

/* Logotipo */
.navbar-header { // header superior
  border-bottom:solid 1px #e5e5e5;
  position: fixed;
  top:0;
  left:0;
  height: 50px;
  z-index: 97;
  width:100%;
  background:linear-gradient(to bottom, #fff 0%, #f8f8f8 100%);
}

.navbar-header img.logotipo {
   left: 42px;
   position: absolute;
}

/* Sidebar lateral */
nav.sidebar {
  -webkit-transition: margin-left 200ms ease-out;
  -moz-transition: margin-left 200ms ease-out;
  -o-transition: margin-left 200ms ease-out;
  transition: margin-left 200ms ease-out;
  width: 200px;
  height: 100%;
  margin-left: -160px;
  float: left;
  margin-bottom: 0px;
  border: none;
  top: 50px;
  border-radius: 0px;
  position:fixed;
}

nav.sidebar li.dropdown:hover {
    background-color:#e5e5e5;
}

/* Primeiro item da sidebar lateral */
nav.sidebar .navbar-nav > li:first-child{
  border-top: none;
}

nav.sidebar .navbar-nav > li{
  border-bottom: 1px #e5e5e5 solid;
}

nav.sidebar .navbar-nav .open .dropdown-menu {
  position: static;
  float: none;
  width: auto;
  margin-top: 0;
  background-color: #FFF;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

nav.sidebar .navbar-collapse, nav.sidebar .container-fluid {
  padding: 0 0px 0 0px;
}

.navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
  color: #777;
}

nav.sidebar li {
  width: 100%;
}

nav.sidebar-aberta, {
//nav.sidebar:hover{
  margin-left: 0px;
}

/*nav.sidebar .navbar-nav .open .dropdown-menu>li>a:hover, nav.sidebar .navbar-nav .open .dropdown-menu>li>a:focus {
  color: #CCC;
  background-color: transparent;
}*/

/* Conteúdo */

.conteudo {
  -webkit-transition: left 200ms ease-out;
  -moz-transition: left 200ms ease-out;
  -o-transition: left 200ms ease-out;
  transition: left 200ms ease-out;
  position:absolute;
  left:45px;
  width: calc(100% - 50px);
  float:left;
  top: 50px;
}

//nav.sidebar:hover ~ .conteudo,
nav.sidebar-aberta ~ .conteudo, {
  left:205px;
  width: calc(100% - 210px);
}

/* Ajusta a exibição dos menus internos e ícones */
nav.sidebar li.dropdown ul.dropdown-menu a {
    padding-right:13px;
    padding-left: 30px;
    height:30px;
}

nav.sidebar li.dropdown ul.dropdown-menu a i {
    margin-top: 3px;
}

/* Ajusta a exibição dos menus internos e ícones quando o menu estiver aberto */
nav.sidebar-aberta li.dropdown a i {
    float: left !important;
    margin-right: 10px !important;
    margin-left:0px;
    width:16px;
}

nav.sidebar-aberta li.dropdown a span.caret {
    float: right !important;
    margin-top:8px;
}

nav.sidebar a.link-dropdown-menuLateral {
  padding-right: 12px;
}

/* CSS para quando a tela fica pequena */
@media (max-width: 800px) {

    body,
    body > div.ember-view,
    html,
    .sidebar-div {
      height: auto;
    }

    .conteudo {
      clear:both;
      width:100%;
      top: 50px;
      left:0px;
    }

    nav.sidebar {
        margin-left:-200px;
        top: 50px;
    }

    /* Nos casos das telas pequenas, com mouse por cima não exibe o menu  */
   // nav.sidebar:hover {
   //     margin-left:-200px;
   // }

    nav.sidebar:hover ~ .conteudo {
      left:0px;
      width: 100%;
    }

    /* Nos casos das telas pequenas, quando aberta via botão superior, deve mostrar independente de estar com mouse por cima ou não */
    nav.sidebar-aberta {
        margin-left:0px !important;
        position:fixed;
        z-index:98;
        box-shadow: 5px 0px 10px grey;
    }

    nav.sidebar-aberta ~ .conteudo {
        left:0px !important;
        width: 100% !important;
    }

    ul.navbar-nav {
        margin:0px;
    }

    ul.navbar-top-links span.nomeUsuario {
        display: none;
    }

    ul.navbar-top-links ul.dropdown-menu {
        margin-left: -105px;
    }

    ul.navbar-top-links li.dropdown {
      background-color: #FFF;
    }

}
