
body.mostrando-modal-centralizado {
  overflow: hidden;
}

.wrapper-centralizado-com-scroll {
  position: fixed;
  z-index: 99;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  overflow-y: scroll;
}

.overlay-centralizado-com-scroll {
  display: flex;
  display: -webkit-flex;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  justify-content: center;
  -webkit-justify-content: center;
  min-height: 100vh;
  padding: 1em;
  position: relative;
  height: auto;
}

// .overlay-centralizado-com-scroll.translucent {
//   background-color: rgba(0,0,0,0.5);
//   opacity: 1;
// }

/* basic modal style (an example, this is not necessary for the centering) */
.container-centralizado-com-scroll {
  position: relative;
  background-color: white;
  min-width: 30em;
  //max-width: 650px;
  //min-height: 20em;
  //padding: 3em;
  //margin-top: 30px;
  //margin-bottom: 30px;
  box-sizing: border-box;
  box-shadow: 0px 4px 25px 4px rgba(0,0,0,0.30);
}

/* Criado especificamente para anular o valor 50% do width que está sendo atribuído aos componentes tag-modal através da classe col-md-6 */
@media screen and (min-width: 992px) {
  .widthAuto {
     width: auto !important;
  }
}