$cor-barras: #171D59;
$cor-barras-secundaria: #2e6da4;

div.panel-body fieldset {
    overflow-x:auto;
    padding: 2px;
}

table.dataTable {
    background-color: #FFF;
    border: solid 1px #ccc;
    border-radius: 4px;
}

table.dataTable thead td {
    border-bottom: none;
}

table.dataTable tfoot td {
    border-top: solid 1px #ccc;
}

table.dataTable thead tr {
    //background-image: linear-gradient(to bottom, #2e6da4 0%, #2e6da4 100%);
    //color:#FFF;
    background-image: linear-gradient(to bottom,#858585 0,#686868 100%);
    color:#FFF;
}

table.dataTable thead tr.acoes-tabela {
    background: transparent;
    color:#000;
}

table.dataTable tbody tr:hover {
    background-image: linear-gradient(to bottom, #E0FFFF 0%, #E0FFFF 100%);
}

table.dataTable thead th {
    border: 1px solid #ddd;
    border-left-width: 0;
}

table.dataTable thead th:last-child {
    border-right-width: 0;
}

table.dataTable tbody td {
    border: 1px solid #ccc;
    border-left-width: 0;
    border-bottom-width: 0;
}

table.dataTable tbody td:last-child {
    border-right-width: 0;
}

table.dataTable th.sorting_asc {
    background-image: url("../assets/img/sort_asc.png");
}

table.dataTable th.sorting_desc {
    background-image: url("../assets/img/sort_desc.png");
}

table.table-striped > tbody > tr:nth-of-type(odd) {
    background-color:#e1e1e1;
}

table.dataTable ul.opcoes-filtro-tabela ul.dropdown-menu li.dropdown {
    padding: 2px 0;
}

table.dataTable ul.opcoes-filtro-tabela ul.dropdown-menu li.dropdown:hover {
    background-color:#e5e5e5;
}

@media (max-width: 800px) {
    table.dataTable thead tr.acoes-tabela div.div-acoes-tabela,
    table.dataTable tfoot div.div-acoes-tabela {
        clear:both !important;
        float:left !important;
        text-align: left !important;
        padding: 5px 0;
    }

    table.dataTable thead th {
        display: list-item;
        list-style: none;
        width:auto !important;
    }

    table.dataTable tbody td {
        display: list-item;
        list-style: none;
    }

    table.dataTable tbody td:last-child {
        border-bottom: solid 2px #686868;
    }

}