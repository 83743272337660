/*BASEADO EM http://thecodeplayer.com/walkthrough/css3-breadcrumb-navigation*/

.sac_breadcrumb {
	/*centering*/
	display: inline-block;
	overflow: hidden;
	border-radius: 5px;
	margin-top:3px;
}

.sac_breadcrumb a {
	text-decoration: none;
	outline: none;
	display: block;
	float: left;
	font-size: 12px;
	line-height: 25px;
	/*color: white;*/
	color: #333;
  	font-weight: normal;
	/*need more margin on the left of links to accomodate the numbers*/
	padding: 0 10px 0 40px;
	/*background: #337ab7;*/
	background: white;
  /*background: linear-gradient(#2260a6, #558bc8);*/
	position: relative;
}

/*since the first link does not have a triangle before it we can reduce the left padding to make it look consistent with other links*/
.sac_breadcrumb a:first-child {
	padding-left: 26px;
	border-radius: 5px 0 0 5px; /*to match with the parent's radius*/
}
.sac_breadcrumb a:first-child:before {
	left: 14px;
}
.sac_breadcrumb a:last-child {
	border-radius: 0 5px 5px 0; /*this was to prevent glitches on hover*/
	padding-right: 20px;
}

/*adding the arrows for the breadcrumbs using rotated pseudo elements*/
.sac_breadcrumb a:after {
	content: '';
	position: absolute;
	top: 0;
	right: -18px; /*half of square's length*/
	/*same dimension as the line-height of .sac_breadcrumb a */
	width: 36px;
	height: 36px;
	/*as you see the rotated square takes a larger height. which makes it tough to position it properly. So we are going to scale it down so that the diagonals become equal to the line-height of the link. We scale it to 70.7% because if square's:
	length = 1; diagonal = (1^2 + 1^2)^0.5 = 1.414 (pythagoras theorem)
	if diagonal required = 1; length = 1/1.414 = 0.707*/
	transform: scale(0.707) rotate(45deg);
	/*we need to prevent the arrows from getting buried under the next link*/
	z-index: 1;
	/*background same as links but the gradient will be rotated to compensate with the transform applied*/
	/*background: #337ab7; */
	background: white;
	/*background: linear-gradient(135deg, #666, #333);*/
	/*stylish arrow design using box shadow*/
	box-shadow:
		2px -2px 0 2px rgba(0, 0, 0, 0.4),
		3px -3px 0 2px rgba(255, 255, 255, 0.1);
	/*
		5px - for rounded arrows and
		50px - to prevent hover glitches on the border created using shadows*/
	border-radius: 0 5px 0 50px;
}
/*we dont need an arrow after the last link*/
.sac_breadcrumb a:last-child:after {
	content: none;
}

/*
.sac_breadcrumb a.ativo:after, .sac_breadcrumb a:hover:after {
	background: #337ab7;
  background: linear-gradient(135deg, #333, #000);
}
*/
.sac_breadcrumb a:hover, .sac_breadcrumb a:hover:after,
.sac_breadcrumb a.ativo, .sac_breadcrumb a.ativo:after {
	/*background: white;*/
	/*background: linear-gradient(135deg, white, #337ab7);*/
	background: #337ab7;
	transition: all 0.3s;
}

.sac_breadcrumb a:hover, .sac_breadcrumb a.ativo {
	/*color: #333;*/
	color: white;
}

.sac_breadcrumb a.ativo, .sac_breadcrumb a.ativo:after {
  font-weight: bold;
}
