.transparencia {
    height: 100%;
    background: #FFF;
    font-family: Arial;
}

/* CSJT*/
.transparencia .wrap {
	margin: 0 auto
}

@media ( max-width :1130px) {
	.transparencia .wrap {
		width: 90%;
		max-width: 1023px;
	}
}

@media ( min-width :1130px) and (max-width: 1180px) {
	.transparencia .wrap {
		width: 1080px;
		margin: 0 auto;
	}
}

@media ( min-width :1180px) {
	.transparencia .wrap {
		width: 1130px;
	}
}

/* CABEÇALHO */
.transparencia .cabecalho-transparencia .barra-superior {
    background-color: #103e66;
    width: 100%;
    height: 10px;
} 

.transparencia .cabecalho-transparencia .logotipo-jt {
    padding: 10px;
    //overflow:auto;
    display: flex;
}

.transparencia .cabecalho-transparencia .logotipo-jt img {
    //float: left;
    margin-right: auto;
}

.transparencia .cabecalho-transparencia .logotipo-jt h1 {
    //float: right;
    align-self: center;
    font-size: 20px;
}

.transparencia .cabecalho-transparencia .barra-inferior {
    background-color: #eee;
    width: 100%;
    height: 20px;
} 

/* CONTEÚDO */

.transparencia .conteudo-transparencia {
    padding: 20px 0;
}

.transparencia .conteudo-transparencia .panel-heading {
    color: #fff;
    background: #337ab7;
    border: #337ab7;
}

.transparencia .conteudo-transparencia table.dataTable th {
    background: #337ab7;
    border-color: #2e6da4;
    color: #FFF;
    width: 20%;
}

.transparencia .conteudo-transparencia table.dataTable td {
    text-align: left;
}

.transparencia .conteudo-transparencia ul.listagem {
    list-style-type: none;
    padding: 0;
}

.transparencia .conteudo-transparencia ul.listagem > li.item-listagem {
    margin-bottom: 20px;
}

.transparencia .conteudo-transparencia ul.listagem > li.item-listagem:nth-of-type(odd) {
    background-color: #e9e9e9;
}


/* BOXES */

.transparencia .conteudo-transparencia .box h2, .box p {
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
}

.transparencia .conteudo-transparencia .box {
    border-top: 3px solid #337ab7;
    border-radius: 5px;
    box-shadow: 0px 30px 40px -20px hsl(229, 6%, 66%);
    padding: 30px;
    margin: 20px;  
    width: calc(33% - 40px);
    float: left;
}

.transparencia .conteudo-transparencia .box h2 {
    color: #000;
    font-weight: 600;
    min-height: 32px;
}

.transparencia .conteudo-transparencia .box p {
    color: #000;
}

.transparencia .conteudo-transparencia .box img {
    float: right;
    width: 40px;
}

@media (max-width: 450px) {

    .transparencia .conteudo-transparencia .box {
        height: 200px;
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 20px;  
    }
}

@media (max-width: 950px) and (min-width: 450px) {

    .transparencia .conteudo-transparencia .box {
        text-align: center;
        height: 180px;
        width: 100%;
        margin-right: 0px;
        margin-left: 0px;
        margin-bottom: 20px;  
    }
}

@media (min-width: 950px) {
    .transparencia .conteudo-transparencia .boxes {
        display: flex;
        justify-content: center;
        align-items: start;
    }

    .transparencia .conteudo-transparencia .box-down {
        position: relative;
        top: 150px;
    }       
}