//Ajusta formato do select2
.select2-container .select2-choice {
  line-height: 2.5; //aumenta a altura do botão com a setinha para baixo
  height: 100%; //aumenta a altura do select em relação ao "campo" que está por trás do select
  border: none; //tira a borda do select em si, ficando apenas a borda do "campo" que está atrás
}

//Ajusta contraste das opções selecionadas do select2
.select2-highlighted .text-muted {
  color: #ddd;
}

.botao-apenas-icone {
  background-color: Transparent;
  background-repeat:no-repeat;
  border: none;
  cursor: hand;
  overflow: hidden;
  outline: none;
}

.margem-inferior {
  margin-bottom: 10px;
}

.modal-centralizada {
  margin:auto;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-right: 5px;
    padding-left: 10px;
}

/* Tabelas */
div.dataTables_scrollHeadInner,
table.containerTabela {
   width:100% !important;
}

/* input group padrão dos campos de texto*/
/*.input-group { margin-bottom: 8px; }*/
.input-group-coluna-dupla { float:left !important; }
.input-group-coluna-dupla-esquerda { padding-right:2px !important; }
.input-group-coluna-dupla-direita  { padding-left: 2px !important; }
/*.input-group-addon { text-align: right; padding-right: 3%; background-color: #F7F7F7; font-weight:bold; }*/
.input-label-20 { width: 20%; }
.input-label-25 { width: 25%; }
.input-label-30 { width: 30%; }
.input-label-35 { width: 35%; }
.input-label-40 { width: 40%; }
.input-label-45 { width: 45%; }
.input-label-50 { width: 50%; }
.input-label-55 { width: 55%; }
.input-label-60 { width: 60%; }

/* Botões */
div .centerButtons { clear:both; } // Quebrar linha na div de botões

/* TextArea */
textarea.tag-textarea { resize:vertical; } // Não redimensionar textarea

/* Panel */
.panel-caixa { padding:1em; margin-bottom:1.5em; } // Ajustar layout de div de painel de agrupamento (ex.: agrupamento de valor unitário na tela de orçamento da requisição)

/* input-sm */
span.input-group-btn button.input-sm { padding: 4px 12px; } // Centraliza imagem do botão input-sm

/* Linhas de painéis*/
div.row { margin-left:0; margin-right:0; }

/* Spinner */
div#ajaxBusy {
    display:none;
    margin:0px;
    padding-left:0px;
    padding-right:0px;
    padding-top:0px;
    padding-bottom:0px;
    background-color: grey;
    opacity: 0.8;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 1000000;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-image: url('../assets/img/ajax-loader.gif');
    background-position: center;
    background-repeat: no-repeat
}

/* Form group
.panel-body .form-group {
	margin-right:0px;
	margin-left:0px;
}*/

/* Área de relatórios - ao lado do breadcrumb */
div.sac_relatorios {
    position:absolute;
    top:0;
    right: 0;
}

div.sac_relatorios a {
    padding-top: 7px;
    padding-bottom: 5px;
}

/* CSS para quando a tela fica pequena */
@media (max-width: 800px) {
    div.sac_relatorios ul.dropdown-menu {
        position: relative;
    }
}

.alertDiscreto {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* CSS para posicionar os pop-ups gritter mais abaixo
a fim de não se sobrepor ao menu de usuário (logoff)*/
div#gritter-notice-wrapper {
	top:15%;
}

@media screen and (min-width: 535px) {
	.rescisao.col-md-4, .rescisao.col-lg-2 {
		width: 500px !important;
		float: none;
	}
}
