.botaoJustapostoCampo {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.botaoExtremidadeCampo {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
