$cor-barras: #171D59;
$cor-barras-secundaria: #2e6da4;

// Barra dos painéis
.panel-primary > .panel-heading {
    background-color: $cor-barras;
    border-color: $cor-barras;
    background-image: linear-gradient(to bottom, $cor-barras-secundaria 0%, $cor-barras 100%);
}

// Breadcrumb
.sac_breadcrumb a:hover, .sac_breadcrumb a:hover:after, .sac_breadcrumb a.ativo, .sac_breadcrumb a.ativo:after {
    background-color: $cor-barras;
    background-image: linear-gradient(to bottom, $cor-barras-secundaria 0%, $cor-barras 100%);
}

// Botões
.btn-primary {
    background-color: $cor-barras;
    background-image: linear-gradient(to bottom, $cor-barras-secundaria 0%, $cor-barras 100%);
}
.btn-primary:hover {
    background-image: linear-gradient(to bottom, $cor-barras 0%, $cor-barras-secundaria 100%);
}